import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';
import SoundcloudLogo from '../svg/Soundcloud';
import YouTubeLogo from '../svg/YouTube';
import BandcampLogo from '../svg/Bandcamp';

const styles = theme => ({
	root: {
		marginTop: theme.spacing.unit * 4
	},
	leftIcon: {
		marginRight: theme.spacing.unit * 2,
		height: 50,
		width: 50,
		verticalAlign: 'center'
	},
	btn: {
		height: 60,
		marginTop: theme.spacing.unit,
		align: 'left'
	}
});

class availableOn extends React.Component {

	render () {
		const {classes, youtube, soundcloud, bandcamp} = this.props;

		return (
			<div className={classes.root}>
				<Typography align={'center'} variant={'h3'}>Available on</Typography>
				{bandcamp ?
					<Button target="_blank" rel="noopener" fullWidth
					        href={'https://felononbass.bandcamp.com/' + bandcamp} className={classes.btn}>
						<div className={classes.leftIcon}><BandcampLogo black/></div> Bandcamp
					</Button>
						: null}
				{soundcloud ?
					<Button target="_blank" rel="noopener" fullWidth
					        href={'https://soundcloud.com/felononbass/' + soundcloud} className={classes.btn}>
						<div className={classes.leftIcon}><SoundcloudLogo black/></div> Soundcloud
					</Button>
					: null}
				{youtube ?
					<Button target="_blank" rel="noopener" fullWidth href={'https://youtu.be/' + youtube}
					        className={classes.btn}>
						<div className={classes.leftIcon}><YouTubeLogo black/></div>YouTube
					</Button>
					: null}
			</div>
		);
	}
};

availableOn.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(availableOn));
