import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import withRoot from '../withRoot';
import Layout from '../components/layout';
import {graphql} from 'gatsby';
import ImgLoader from '../components/imageLoader';
import Typography from '@material-ui/core/Typography/Typography';
import Hidden from '@material-ui/core/Hidden/Hidden';
import Cover from '../components/cover';
import AvailableOn from '../components/availableOn';
import BandcampEmbed from '../components/embeds/bandcamp';
import SoundCloudEmbed from '../components/embeds/soundcloud';
import YouTubeEmbed from '../components/embeds/youtube';
import Helmet from 'react-helmet';

const styles = theme => ({
	root: {},
	header: {
		height: 500,
	},
	headerProtector: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		background: theme.palette.type === 'light' ? 'linear-gradient(180deg, #000000 0%, rgba(183, 183, 183, 0) 56.35%, rgba(255, 255, 255, 0.17) 69.06%, #FFFFFF 88.95%)' : 'linear-gradient(180deg, #000000 0%, rgba(101, 101, 101, 0) 56.35%,' +
			' rgba(32, 33, 36, 0.17) 69.06%, #202124 88.95%)',
		height: 500,
	},
	grid: {
		width: '100%',
		maxWidth: 1440,
		margin: 'auto',
		background: 'transparent',
		position: 'relative',
		top: -200,
	},
	noHeader: {
		height: 300,
	},
	releaseName: {},
	player: {
		marginTop: theme.spacing.unit * 2,
	},
	meta: {
		display: 'none',
	},
});

class ReleasePage extends React.Component {

	render () {
		const {classes} = this.props;
		const release = this.props.data.releasesJson;
		const isAlbum = release.type === 'album';

		return (
			<Layout>
				<Helmet>
					<title itemProp="name" lang="en">{release.name}</title>
					<link
						rel="canonical"
						href={`https://felononbass.com` + release.fields.slug}
					/>
					<meta name="Description"
					      content={release.name + ' ' + (release.subName || release.type.replace(/^./, release.type[ 0 ].toUpperCase())) + ((release.type === 'cover' || release.type === 'remix') ? ' of ' + release.originalArtist : null) + (release.feat ? ' · with ' + release.feat : null) + '·' + (release.releaseDate.slice(0, 4))}/>
					<div className={classes.meta} itemScope itemType={'http://schema.org/BreadcrumbList'}>
						<div className={classes.meta} itemScope itemProp={'itemListElement'}
						     itemType={'http://schema.org/ListItem'}>
							<meta itemProp={'item'} content={'https://felononbass.com/'}/>
							<meta itemProp={'name'} content={'FelonOnBass'}/>
							<meta itemProp={'position'} content={'1'}/>
						</div>
						<div className={classes.meta} itemScope itemProp={'itemListElement'}
						     itemType={'http://schema.org/ListItem'}>
							<meta itemProp={'item'} content={'https://felononbass.com/releases/'}/>
							<meta itemProp={'name'} content={'Releases'}/>
							<meta itemProp={'position'} content={'2'}/>
						</div>
						<div className={classes.meta} itemScope itemProp={'itemListElement'}
						     itemType={'http://schema.org/ListItem'}>
							<meta itemProp={'item'} content={'https://felononbass.com' + release.fields.slug}/>
							<meta itemProp={'name'} content={release.name}/>
							<meta itemProp={'position'} content={'3'}/>
						</div>
					</div>
				</Helmet>
				<div className={classes.root} itemScope
				     itemType={isAlbum ? 'https://schema.org/MusicAlbum' : 'https://schema.org/MusicRecording'}>
					<meta itemProp={'name'} content={release.name + ' ' + release.subName}/>
					<meta itemProp={'dateCreated'} content={release.releaseDate}/>
					<meta itemProp={'byArtist'} content={'FelonOnBass'}/>
					{release.originalArtist ? <meta itemProp={'isBasedOn'} content={release.originalArtist}/> : null}
					<meta itemProp={'description'} content={release.description}/>
					{release.feat ? <meta itemProp={'contributor'} content={release.feat}/> : null}
					{release.youtube ? <meta itemProp={'url'} content={'https://youtu.be/' + release.youtube}/> : null}
					{release.soundcloud ? <meta itemProp={'url'}
					                            content={'https://soundcloud.com/felononbass/' + release.soundcloud}/> : null}
					{release.bandcamp ? <meta itemProp={'url'}
					                          content={'https://felononbass.bandcamp.com/' + release.bandcamp}/> : null}

					{release.header ?
						<div className={classes.header}>

							<ImgLoader imgName={release.header}/>

							<div className={classes.headerProtector}/>
						</div> :
						<div className={classes.noHeader}/>
					}


					<Grid container spacing={3} justify={'space-around'} className={classes.grid}>

						<Grid item container xs={12} spacing={4} justify={'center'}>
							<Grid item xs={12} sm={6} md={4}>
								{release.cover ? <Cover imgName={release.cover} release={release}/> :
									<Cover placeholder release={release}/>}
								<Hidden smUp><Typography align={'center'} variant={'h1'}
								                         className={classes.releaseName}
								                         style={{marginTop: 20}}>{release.name}</Typography>
									<Typography align={'center'} variant={'h4'}>
										{release.subName || release.type.replace(/^./, release.type[ 0 ].toUpperCase())}{(release.type === 'cover' || release.type === 'remix') ? ' of ' + release.originalArtist : null} {release.feat ? ' · with ' + release.feat : null} · {release.releaseDate.slice(0, 4)}
									</Typography></Hidden>
								<AvailableOn bandcamp={release.bandcamp} soundcloud={release.soundcloud}
								             youtube={release.youtube}/>
							</Grid>
							<Grid item xs={12} sm={6} md={6}>
								<Hidden xsDown><Typography align={'left'} variant={'h1'}
								                           className={classes.releaseName}
								                           style={{marginTop: 100}}>{release.name}</Typography>
									<Typography align={'left'} variant={'h4'}>
										{release.subName || release.type.replace(/^./, release.type[ 0 ].toUpperCase())}{(release.type === 'cover' || release.type === 'remix') ? ' of ' + release.originalArtist : null} {release.feat ? ' · with ' + release.feat : null} · {release.releaseDate.slice(0, 4)}
									</Typography>
								</Hidden>
								<Typography variant={'body1'} align={'left'}>{release.annotation}</Typography>
								<div className={classes.player}>
									{release.bandcampID ? <BandcampEmbed release={release}/> :
										release.soundcloudID ? <SoundCloudEmbed release={release}/> :
											release.youtube ? <YouTubeEmbed release={release}/> : null}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Layout>

		);
	}
}

ReleasePage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(ReleasePage));

export const query = graphql`query ($slug: String!) {
  releasesJson(fields: {slug: {eq: $slug}}) {
    name
    subName
    releaseDate
    type
    feat
    originalArtist
    license
    commerce
    cover
    header
    lyrics
    youtube
    soundcloud
    soundcloudID
    bandcamp
    bandcampID
    content
    annotation
    fields {
      slug
    }
  }
}
`;