import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../../withRoot';

const styles = theme => ({
	root: {},
});

class youtube extends React.Component {

	render () {
		const {classes, release} = this.props;

		return (
			<div className={classes.root}>
				<iframe title={'YouTube embed'} id="ytplayer" width="100%" height="500"
				        src={'https://www.youtube-nocookie.com/embed/' + release.youtube}
				        frameBorder="0" allow="encrypted-media; picture-in-picture" allowFullScreen/>
			</div>
		);
	}
};

youtube.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(youtube));
