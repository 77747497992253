import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../../withRoot';

const styles = theme => ({
	root: {},
	placeHolder: {
		width: '100%',
		height: '100%',
		float: 'left',
	},
	icon: {
		maxHeight: 100,
		maxWidth: 100,
	},
});

class soundcloud extends React.Component {

	render () {
		const {classes, release} = this.props;

		return (
			<div className={classes.root}>

				<iframe width="100%" height="100%" scrolling="no" frameBorder="no" allow="autoplay"
				        title={'SoundCloud embed'}
				        src={'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/' + release.soundcloudID + '&color=%238E1A8D&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'}/>

			</div>
		);
	}
};

soundcloud.propTypes = {
	classes: PropTypes.object.isRequired,
	release: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(soundcloud));
